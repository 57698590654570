import React from 'react';

const Error = props => (
    <div>
        {Object.entries(props).map(([err, val]) => (
            
            <pre style={{padding:'0',margin:0}}>
                <h5 style={{margin:'0',color:'#8B0000'}}>{JSON.stringify(val, '', ' ')}</h5>
            </pre>
        ))}
    </div>
);

export default Error;
