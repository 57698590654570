import React from "react"
import { Link } from 'gatsby'
import { navigate } from '@reach/router'
import { setUser, isLoggedIn, setCard } from '../util/auth'
import Error from '../pages/404'
import { Auth } from 'aws-amplify'
import awsConfig from '../util/scoop-Setting';
import isLocalhost from '../util/localHost'
import { API, graphqlOperation } from 'aws-amplify';


 

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [
  localRedirectSignIn,
  productionRedirectSignIn,
] = awsConfig.oauth.redirectSignIn.split(",");

const [
  localRedirectURI,
  productionRedirectURI,
] = awsConfig.oauth.redirectURI.split(",");
 

const [ 
  localRedirectSignOut,
  productionRedirectSignOut,
] = awsConfig.oauth.redirectSignOut.split(",");

const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
	redirectURI: isLocalhost ? localRedirectURI : productionRedirectURI,
    redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
  }
}

Auth.configure(updatedAwsConfig);
const initialState = {
  username: ``,
  newPassword: ``,
  authCode: '',
  stage: 0,
  error: ''
}

class ForgotPassword extends React.Component {
  state = initialState

  handleUpdate = (event) => {
	 
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  forgotPassword = async() => {
    const { username } = this.state
    try {
      await Auth.forgotPassword( username)
      this.setState({ stage: 1 })
    } catch (err) {
      this.setState({ error: err })
      console.log('error signing up...', err)
    }
  }

  confirmAuthCode = async() => {
    const { username, newPassword, authCode } = this.state
    try {
      await Auth.forgotPasswordSubmit(username, authCode,newPassword)
      alert('Successfully signed up!')
      navigate("/app/login")
    } catch (err) {
      this.setState({ error: err })
      console.log('error confirming signing up...', err)
    }
  }

  render() {
    return (
      <div>
        <h1>Forgot Password</h1>
        {
          this.state.stage === 0 && (
            <div style={styles.formContainer}>
              {this.state.error && <Error errorMessage={this.state.error}/>}
             
			  <input
                onChange={this.handleUpdate}
                placeholder='Email'
                name='username'
                value={ this.state.username}
                style={styles.input}
              />
              
            
              <div style={styles.button} onClick={this.forgotPassword}>
                <span style={styles.buttonText}>Forgot Password</span>
              </div>
            </div>
          )
        }
        {
          this.state.stage === 1 && (
		  
		    
            <div style={styles.formContainer}>
			  <p>Please check your email for an auth code </p>
              {this.state.error && <Error errorMessage={this.state.error}/>}
			 <input
                
                placeholder={this.state.username}
                name='username'
                value={ this.state.username}
                style={styles.input}
              />
              <input
                onChange={this.handleUpdate}
                placeholder='Authorization Code'
				autocomplete='off'
                name='authCode'
                value={this.state.authCode}
                style={styles.input}
              />
			  <input
                onChange={this.handleUpdate}
                placeholder='Password'
                name='newPassword'
				autocomplete='new-password'
                value={this.state.password}
                type='password'
                style={styles.input}
              />
              <div style={styles.button} onClick={this.confirmAuthCode}>
                <span style={styles.buttonText}>Confirm Code</span>
              </div>
            </div>
          )
        }
        <Link to="/app/login">Sign In</Link>
      </div>
    )
  }
}

const styles = {
  input: {
    height: 40, margin: '10px 0px', padding: 7
  },
  formContainer: {
    display: 'flex', flexDirection: 'column'
  },
  button: {
    backgroundColor: 'rebeccapurple', padding: '15px 7px', cursor: 'pointer', textAlign: 'center', marginBottom: 10
  },
  buttonText: {
    color: 'white'
  }
}

export default ForgotPassword
