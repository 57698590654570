import * as React from "react"
import Layout from "./layout"
import "../styles/style.css"
import{getCurrentUser, getCurrentCard,logout,isLoggedIn} from '../util/auth'
import { navigate } from '@reach/router'
import QRCode  from 'qrcode.react'
import {Auth} from 'aws-amplify'
import awsConfig from '../util/scoop-Setting'
import isLocalhost from '../util/localHost'
import SaveToGooglePay from '@google-pay/save-button-react'
import GooglePayButton from '@google-pay/button-react';
// markup
// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [
    localRedirectSignIn,
    productionRedirectSignIn,
  ] = awsConfig.oauth.redirectSignIn.split(",");
  
  const [
    localRedirectURI,
    productionRedirectURI,
  ] = awsConfig.oauth.redirectURI.split(",");
  
  
  const [
    localRedirectSignOut,
    productionRedirectSignOut,
  ] = awsConfig.oauth.redirectSignOut.split(",");
  
  const updatedAwsConfig = {
    ...awsConfig,
    oauth: {
      ...awsConfig.oauth,
      redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
      redirectURI: isLocalhost ? localRedirectURI : productionRedirectURI,
      redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
    }
  }
  
  Auth.configure(updatedAwsConfig);
  
  
  
  

const MyProfilePage= () => {
  
  if (!(isLoggedIn()))navigate('/profile')
  const theCard = getCurrentCard().toString();

  
  const myArr2 =  theCard .split("=")
  console.log("card " + myArr2[0] + ' - ' + myArr2[1]);

  const cardData =  JSON.parse(myArr2[1].toString().substr(0,myArr2[1].length-1))
  const card = cardData.cardNum ? cardData.cardNum:'Unknown'
  console.log("Its a card " + card)
  const cardString =  getCurrentCard()
  
  const coffeeCardJpg = '../../ScoopCombined'.toString().concat((cardData.loyaltyStamps.coffeeStamps%8)*8+(cardData.loyaltyStamps.gelatoStamps%8)).concat('.jpg')
  const coffeeCardTxt = '../../ScoopCombined'.toString().concat((cardData.loyaltyStamps.coffeeStamps%8)*8+(cardData.loyaltyStamps.gelatoStamps%8)).concat('.jpg')

  return (
    
      <div style={{marginTop:'25px'}} className = "contents">
        <h1 id="title">Welcome {getCurrentUser().name}</h1>
        
        <div className={'card-description'}>

          <br/> 
        <img
        alt={coffeeCardTxt}
        src={coffeeCardJpg}/>

          <br/>
             
          <QRCode value={card} />,
          <br/>
          {cardData.gpayCard ?  <SaveToGooglePay jwt={cardData.gpayCard } height="standard" textsize="small" /> : <br/>} <br/>

          Scan this for discount<br/>
        </div>
      </div>
    


    )
}


export default MyProfilePage
