  
import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/layout"
import Details from "../components/details"
import EmailFlow from "../components/emailflow"
import ForgotPassword from "../components/forgotpassword"
import Home from "../pages/index"
import Login from "./login"
import SignUp from "../components/SignUp"
import PrivateRoute from "../components/PrivateRoute"
import Contact from "./contact"

import Menu from "./menu"

const App = () => (
  <Layout>
    <Router>
      <PrivateRoute path="/app/profile" component={Details} />
	    <ForgotPassword path="/app/forgotPassword"/>
	    <EmailFlow path="/app/emailflow" />
      <Login path="/app/login" />
      <SignUp path="/app/signup" />
      <Contact path="/app/contact/"/>
      <Menu path="/app/menu/"/>
    </Router> 
  </Layout>
)

export default App