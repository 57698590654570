import React from "react"
import { navigate} from "@reach/router"
import { Link } from 'gatsby'
import Error from '../pages/404'
import { Auth } from 'aws-amplify'
import awsConfig from '../util/scoop-Setting';
import isLocalhost from '../util/localHost'



// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [
  localRedirectSignIn,
  productionRedirectSignIn,
] = awsConfig.oauth.redirectSignIn.split(",");

const [
  localRedirectURI,
  productionRedirectURI,
] = awsConfig.oauth.redirectURI.split(",");


const [
  localRedirectSignOut,
  productionRedirectSignOut,
] = awsConfig.oauth.redirectSignOut.split(",");

const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
	redirectURI: isLocalhost ? localRedirectURI : productionRedirectURI,
    redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
  }
}

Auth.configure(updatedAwsConfig);

const initialState = {
  name: ``,
  password: ``,
  email: '',
  authCode: '',
  stage: 0,
  error: ''
}


class SignUp extends React.Component {
  state = initialState

  handleUpdate = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  signUp = async() => {
    const { username, password, email, name } = this.state
    try {
      await Auth.signUp({ username,  password, attributes: { email,name}})
      this.setState({ stage: 1 })
    } catch (err) {
      this.setState({ error: err })
      console.log('error signing up...', err)
    }
  }

  confirmSignUp = async() => {
    const { username, authCode } = this.state
    try {
      await Auth.confirmSignUp(username, authCode)
      alert('Successfully signed up!')
      navigate("/app/login")
    } catch (err) {
      this.setState({ error: err })
      console.log('error confirming signing up...', err)
    }
  }

  render() {
    return (
      <>
      <h1>Sign Up</h1>
      <div style={styles.container}>
        <div style={styles.width}>
        
        {
          this.state.stage === 0 && (
            <div style={styles.formContainer}>
              {this.state.error && <Error errorMessage={this.state.error}/>}
             
			  <input
                onChange={this.handleUpdate}
                placeholder='Email'
                name='username'
                value={ this.state.username}
                style={styles.input}
              />
              <input
                onChange={this.handleUpdate}
                placeholder='Password'
                name='password'
                value={this.state.password}
                type='password'
                style={styles.input}
              />
               <input
                onChange={this.handleUpdate}
                placeholder='Name'
                name='name'
                value={this.state.name}
                style={styles.input}
              />
            
              <div style={styles.button} onClick={this.signUp}>
                <span style={styles.buttonText}>Sign Up</span>
              </div>
              
            </div>
          )
        }
        {
          this.state.stage === 1 && (
            <div style={styles.formContainer}>
              {this.state.error && <Error errorMessage={this.state.error}/>}
              <input
                onChange={this.handleUpdate}
                placeholder='Authorization Code'
                name='authCode'
                value={this.state.authCode}
                style={styles.input}
              />
              <div style={styles.button} onClick={this.confirmSignUp}>
                <span style={styles.buttonText}>Confirm Sign Up</span>
              </div>
            </div>
          )
        }
        <div style={styles.signInButton}>
        <Link style={{color:'white'}} to="/app/login">Sign In</Link>
        </div>
        </div>
      </div>
      </>
    )
  }
}

const styles = {
  input: {
    height: 40, margin: '10px 0px', padding: 7
  },
  formContainer: {
    display: 'flex', flexDirection: 'column'
  },
  button: {
    backgroundColor: 'rebeccapurple', padding: '15px 7px', cursor: 'pointer', textAlign: 'center', marginBottom: 10 ,borderRadius:'20px'
  },
  signInButton:{
    backgroundColor: 'rebeccapurple', padding: '15px 7px', cursor: 'pointer', textAlign: 'center', marginBottom: 10 ,borderRadius:'20px'
  },
  buttonText: {
    color: 'white'
  },
  container:{
    margin:' auto auto',
    maxWidth: '25vw',
    padding: '1rem',
    textAlign: 'center',
    verticalAlign: 'middle',
    display:'flex',
    alignItems: 'center',
  },
  width:{
    width:'25vw'
  }
}

export default SignUp
