import React from "react"
import { Link } from 'gatsby'
import { navigate } from '@reach/router'
import { setUser, isLoggedIn, setCard } from '../util/auth'
import Error from './Error'
import { Auth } from 'aws-amplify'
import awsConfig from '../util/scoop-Setting';
import isLocalhost from '../util/localHost'
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';

 

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [
  localRedirectSignIn,
  productionRedirectSignIn,
] = awsConfig.oauth.redirectSignIn.split(",");

const [
  localRedirectURI,
  productionRedirectURI,
] = awsConfig.oauth.redirectURI.split(",");
 

const [ 
  localRedirectSignOut,
  productionRedirectSignOut,
] = awsConfig.oauth.redirectSignOut.split(",");

const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
	redirectURI: isLocalhost ? localRedirectURI : productionRedirectURI,
    redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
  }
}

Auth.configure(updatedAwsConfig);


class EmailFlow extends React.Component {
	
	  

  state = {
    username: ``,
    password: ``,
    error: ``
  }

  handleUpdate = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }
  
  
  login = async() => {
    const { username, password } = this.state
    try {
	  
      // API.graphql(graphqlOperation(queries.retrieveUserDetails, { cardNum : 'c8704471'})).then( val => { console.log("Done"); console.log(val)});
     
      await Auth.signIn(username, password)
      const user = await Auth.currentAuthenticatedUser()
	     
      const userInfo = {
        ...user.attributes,
        username: user.username,
		
      }
      setUser(userInfo)
		   
      navigate("../..")
    } catch (err) {
      this.setState({ error: err })
      console.log('error...: ', err)
    }
  }
 
  render() {
	 
     
	 
   if (isLoggedIn()) navigate('/app/profile')
    return (
      <div style={styles.container}>
      <div style={styles.width}>
        <h1>Sign In</h1>
		
        {this.state.error && <Error errorMessage={this.state.error}/>}
		
	    <div style={styles.formContainer}>
         <input
            onChange={this.handleUpdate}
            placeholder='Username'
            name='username'
            value={this.state.username}
            style={styles.input}
          />
          <input
            onChange={this.handleUpdate}
            placeholder='Password'
            name='password'
            value={this.state.password}
            type='password'
            style={styles.input}
          />
          <div style={styles.button} onClick={this.login}>
            <span style={styles.buttonText}>Sign In</span>
          </div>
        </div>
        <div style={styles.Button1}>
		      <Link style={{color:'white'}} to="/app/forgotPassword">Forgot Password</Link><br />
        </div>
        <div style={styles.Button1}>
          <Link style={{color:'white'}} to="/app/signup">Sign Up</Link><br />
        </div>
        </div>
      </div>
    )
  }
}

const styles = {
  input: {
    height: 40, margin: '10px 0px', padding: 7
  },
  formContainer: {
    display: 'flex', flexDirection: 'column'
  },
  button: {
    backgroundColor: 'rebeccapurple', padding: '15px 7px', cursor: 'pointer', textAlign: 'center', marginBottom: 10,borderRadius:'20px'
  },
  buttonText: {
    color: 'white'
  },
  width:{
    width:'25vw'
  },
  container:{
    margin:' auto auto',
    maxWidth: '25vw',
    padding: '1rem',
    minHeight: '90vh',
    textAlign: 'center',
    verticalAlign: 'middle',
    display:'flex',
    alignItems: 'center',
  },
  Button1:{
    backgroundColor: 'rebeccapurple', padding: '15px 7px', cursor: 'pointer', textAlign: 'center', marginBottom: 10 ,borderRadius:'20px'
  },
}

export default EmailFlow
